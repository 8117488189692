import styled from "styled-components";
import { FlexipageColors } from "../Global/FlexipageStyles";

export const HeroSectionWrapper = styled.section`
    min-height: 100dvh;
    position: relative;
    overflow: auto;

    &::before {
        content: '';
        background: white;
        width: 100%;
        height: calc(100%);
        position: absolute;
        clip-path: polygon(100% 586px, 0% 100%, 100% 100%);
        z-index: 1;
    }

    .hero-icon-image{
        max-width: 200px;
        max-height: 80px;
        margin: 1em 0;
    }
    .hero-background{
        position: absolute;
        background: ${FlexipageColors.gradientMid};
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center center;
        border-bottom: 1px solid white;
        z-index: 0;
    }

    .hero-container{
        margin: 6em auto;
        width: 100%;
        max-width: 1340px;
        position: relative;
        z-index: 1;
        padding: 86px 20px 0;
        box-sizing: border-box;

        @media screen and (max-width: 1000px) {
            margin: 0 auto 4em;
            padding: 86px 50px 0;
        }
        @media screen and (max-width: ${FlexipageColors.breakpointMobile}) {
            margin: 0 auto 2em;
            padding: 86px 20px 0;
        }

        .lead-section{
            display: grid;
            grid-template-columns: 2fr 3fr;
            width: 100%;
            gap: 40px;
            margin-bottom: 3em;

            &.no-grid{
                display: block;
            }

            @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
            }

            .video-region{
                .video{
                    width: 100%;
                    margin: auto;
                    padding-top: 56.25%;
                    background-color: white;
                    border-radius: 16px;
                    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
                    position: relative;
                    overflow: hidden;
                }
            }

            .hero-image{
                max-height: 700px;
                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 16px;
                    display: block;
                    margin-left: auto;
                }
            }

            .copy-region{
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: white;

                h1, p{
                    color: #FFF;
                }

                h1{
                    margin-bottom: 0.75em;
                    span{
                        color: #46AC92;
                    }
                }
                *:last-child{
                    margin-bottom: 0;
                }
            }
        }

        .hero-panels{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @media screen and (max-width: 800px) {
                flex-direction: column;
            }
        }
    }
`
